<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import {dateUtil} from "@/helpers/date-util";
import {paginationHelper} from "@/helpers/pagination-helper";
import {required} from "vuelidate/lib/validators";

import CustomCardSubtitle from "@/components/custom-card/custom-card-subtitle.vue";

export default {
  components: {
    CustomCardSubtitle,

    Layout,
    PageHeader
  },

  computed: {
    paginationHelper() {
      return paginationHelper
    },

    dateUtil() {
      return dateUtil
    }
  },

  data() {
    return {

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: "",
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        items: []
      },

      modals: {
        createAccountVPN: {
          visibleModal: false,

          form: {
            accountName: ''
          }
        }
      },

      accounts: null,
    };
  },

  validations: {
    modals: {
      createAccountVPN: {
        form: {
          accountName: {required}
        }
      }
    }
  },

  methods: {
    openModal() {
      this.modals.createAccountVPN.visibleModal = true;
    },

    hideModal() {
      this.modals.createAccountVPN.visibleModal = false
      this.modals.createAccountVPN.form = {
        accountName: ''
      }
    },

    getFields() {
      return [
        {key: "id", label: 'Id' },
        {key: "accountName", label: 'Nazwa konta'},
        {key: "action", label: this.$t('table.actions') }
      ]
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('users.title'),
          active: true
        }
      ]
    },
  },

/*  async created() {
    try {

    } catch (error) {
      console.log(error)
    }
  }*/
};
</script>

<template>
  <Layout>
    <PageHeader title="Allegro VPN" :items="getItems()" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <template v-if="accounts">
              <div class="row">
                <custom-card-subtitle title="Akcje"/>

                <div class="col-xl-12">
                  <b-button variant="success" @click="openModal">
                    <i class="mdi mdi-plus mr-2"></i> Dodaj konto do listy
                  </b-button>
                </div>
              </div>

              <ecat-table
                  :table="table"
                  :fields="getFields()"
                  :items="table.items"
              >
<!--                <template v-slot:action="{ item }">
                  <div class="button-items mb-1">
                    <b-button variant="danger" class="btn-sm">
                      <i class="mdi mdi-delete mr-2"></i> Usuń
                    </b-button>
                  </div>
                </template>-->

              </ecat-table>
            </template>
            <template v-else>
              <div class="text-center py-3">
                <h5>{{ $t('message.loading') }}</h5>
                <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <b-modal
        id="modal-1"
        v-model="modals.createAccountVPN.visibleModal"
        :title="`Dodaj nowe konto`"
        title-class="font-18"
        hide-footer
        @hide="hideModal"
        @esc="hideModal">
      <form>
        <div class="form-group">
          <label for="accountName">Nazwa konta</label>
          <input id="accountName" v-model="modals.createAccountVPN.accountName" type="text" class="form-control" />
        </div>

        <div class="text-right">
          <b-button variant="primary">Zapisz</b-button>
        </div>
      </form>
    </b-modal>
  </Layout>
</template>